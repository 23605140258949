import pino from 'pino';
import type { Logger } from './logging-types';
import { env } from '@/app/env';

let logger: Logger;

export function sLog() {
  if (!logger) {
    logger = initLogger();
  }
  return logger ?? console;
}

function initLogger() {
  const config = {
    base: null,
    errorKey: 'error',
    formatters: {
      level: (label: string) => {
        return { level: label };
      },
    },
    level: env.NEXT_PUBLIC_LOG_LEVEL ?? 'info',
    bindings: (bindings: Record<string, unknown>) => {
      return {
        ...bindings,
        node_version: process.version,
        docker_image_tag: env.DOCKER_IMAGE_TAG,
      };
    },
  };

  const jsonLogger = pino(config);

  const serverLogger: Logger = {
    debug(msg, obj) {
      jsonLogger.debug(obj, msg);
    },
    info(msg, obj) {
      jsonLogger.info(obj, msg);
    },
    warn(msg, obj) {
      jsonLogger.warn(obj, msg);
    },
    error(msg, obj) {
      jsonLogger.error(obj, msg);
    },
  };
  return serverLogger;
}

import { sLog } from './server-logging';
import { cLog } from './client-logging';
import { env } from '@/app/env';

export const logger = (() => {
  if (isServer() && isProduction()) {
    return sLog();
  }

  if (isClient() && isProduction()) {
    return cLog();
  }

  return console;
})();

function isServer() {
  return typeof window === 'undefined';
}

function isClient() {
  return typeof window !== 'undefined';
}

function isProduction() {
  return env.NEXT_PUBLIC_NODE_ENV === 'production';
}

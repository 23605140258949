import { z } from 'zod';

const applicationEnvironmentSchema = z
  .enum(['test', 'development', 'production', 'local'])
  .default('local');

export const clientExposedEnvSchema = z.object({
  NEXT_PUBLIC_NODE_ENV: applicationEnvironmentSchema,
  NEXT_PUBLIC_LOG_LEVEL: z
    .enum(['debug', 'info', 'warn', 'error'])
    .default('info'),
});

export const runtimeEnvSchema = clientExposedEnvSchema.extend({
  NODE_ENV: applicationEnvironmentSchema,
  PORT: z.string().transform(Number).default('3000'),
  BASE_URL: z.string().url().default('http://localhost:3000'),
  LOCALE: z.enum(['en', 'sv', 'no']).default('no'),
  DOCKER_IMAGE_TAG: z.string().default('latest'),
  BILPRISER_DATOCMS_READONLY_TOKEN: z.string(),
  // Everything below needs to be optional in ci
  BILPRISER_API_USERNAME_NO: z.string().optional(),
  BILPRISER_API_PASSWORD_NO: z.string().optional(),
  BILPRISER_API_USERNAME_SE: z.string().optional(),
  BILPRISER_API_PASSWORD_SE: z.string().optional(),
  BILPRISER_RECAPTCHA_SITE_KEY: z.string().optional(),
  BILPRISER_RECAPTCHA_SECRET_KEY: z.string().optional(),
  GTM_TRACKING_ID: z.string().optional(),
  BILPRISER_DD_API_KEY: z.string().optional(),
  BILPRISER_DD_API_KEY_ID: z.string().optional(),
  BILPRISER_DD_APPLICATION_ID: z.string().optional(),
  BILPRISER_DD_CLIENT_TOKEN: z.string().optional(),
  DD_SITE: z.enum(['datadoghq.eu']).optional(),
  DD_SERVICE: z.string().optional(),
  DD_ENV: z.string().optional(),
});

export type Env = z.infer<typeof runtimeEnvSchema>;

export const env = (() => {
  // When running in the browser (CSR)
  if (typeof window !== 'undefined') {
    return process.env as unknown as Env;
  }

  // When running in the server (SSR)
  return runtimeEnvSchema.parse(process.env);
})();
